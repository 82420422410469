import img2 from "assets/icons/pool/arbitrum.webp";
import img6 from "assets/icons/pool/link.svg";
import img7 from "assets/icons/pool/wbtc.svg";

export const poolDataArbitrum = [
  {
    icon: img2,
    name: "Arbitrum",
    token: "ARB",
    network: "Arbitrum",
    id: "arbitrum",
    total: 3534851.21682,
    quantity: ["2000-5000", "3000-8000", "5000-12000", "8000-30000"],
    percents: ["2", "5.5", "12", "18"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Arbitrum",
    id: "wrapped-bitcoin",
    total: 150.00402,
    quantity: ["0.1-0.5", "0.3-1", "0.5-5", "0.8-100"],
    percents: ["0.4", "1", "2.5", "4"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Arbitrum",
    id: "chainlink",
    total: 122609.35819501,
    quantity: ["500-2000", "1500-5000", "3000-10000", "7000-100000"],
    percents: ["1.5", "3.5", "9", "14"],
    days: ["14", "30", "60", "90"],
  },
];
