import React, { useEffect, useState } from "react"
import {
  Header,
  Footer,
  Main,
  Features,
  Ecosystem,
  Loading,
  Partners,
  Investors,
  Leverage,
  Secure,
  NewsHome, Chains
} from "components"

import "./Home.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"

export const Home = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <div className="home" style={{ backgroundImage: `url(${imgMainBgSrc})` }}>
      {!loaded && <Loading />}
      <Header />
      <Main />
      <Leverage />
      <Features />
      <Partners />
      <Investors />
      {/*<div className="team-content other">*/}
      {/*  <h1 className="team-content-title">Lounge Finance Team</h1>*/}
      {/*  <div className="team-content-wrap">*/}
      {/*    {temData.map((item, index) => (*/}
      {/*      <div className="team-content-item" key={index}>*/}
      {/*        <img src={item.img} loading="lazy" alt="team" className="team-content-item-img" />*/}
      {/*        <dt className="team-content-item-title">{item.title}</dt>*/}
      {/*        <dd className="team-content-item-desc">{item.desc}</dd>*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Ecosystem />
      <Secure />
      <Chains />
      <NewsHome />
      {/*{welcome && (*/}
      {/*  <RefModal*/}
      {/*    onClose={() => {*/}
      {/*      setWelcome(false)*/}
      {/*      setCookiesModal(true)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{cookiesModal && <CookiesModal onClose={() => setCookiesModal(false)} />}*/}
      <Footer />
    </div>
  )
}
