import React from "react"

import srcPath from "assets/pdfs/pdf.pdf"
import {Helmet} from "react-helmet";

export const Docs = (): JSX.Element => {
  return (
    <div className="docs">
      <Helmet>
        <title>Lounge Finance Documentation: Empowering Users & Investors</title>
        <meta name="description" content="Access comprehensive guides and documents at Lounge Finance. Providing clarity and empowerment for our community." />
      </Helmet>
      <embed src={srcPath} />
    </div>
  )
}
