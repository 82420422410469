import "./ConnectModal.scss"

import React, {useEffect, useRef} from "react"
import {ConnectKitButton} from "connectkit";

type Props = {
  onClose: () => void
}

export const ConnectModal = ({ onClose}: Props): JSX.Element => {
  const body = document.body

  useEffect(() => {
    body.style.overflow = "hidden"
    window.scrollTo({
      top: 0,
    })

    body.style.overflow = "hidden"
  }, [body.style.overflow])

  return (
    <div className="connect-modal-wrapper">
      <div
        className="connect-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="connect-modal-content-title">Please connect your wallet to use this page</h2>
        <p className="connect-modal-content-desc">
          This page loads data from smart contracts to display up-to-date information. Without connecting a wallet, this page will not work correctly
        </p>
        <div className="connect-modal-content-footer">
          <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
        </div>
      </div>
    </div>
  )
}
