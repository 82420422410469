import img1 from "assets/icons/pool/eth.svg";
import img2 from "assets/icons/pool/tether.svg";
import img3 from "assets/icons/pool/usdc.svg";
import img5 from "assets/icons/pool/uni.svg";
import img6 from "assets/icons/pool/link.svg";
import img7 from "assets/icons/pool/wbtc.svg";
import img8 from "assets/icons/pool/ape.svg";
import img11 from "assets/icons/pool/bnb.png";
import img12 from "assets/icons/pool/busd.webp";
import img13 from "assets/icons/pool/cake.png";
import img15 from "assets/icons/pool/baby-doge.png";
import img16 from "assets/icons/pool/trust-wallet.png";
import img17 from "assets/icons/pool/gmt.webp";
import img18 from "assets/icons/pool/c98.png";

export const allCoins = [
  {
    id: "ethereum",
    icon: img1,
  },
  {
    id: "tether",
    icon: img2,
  },
  {
    id: "usd-coin",
    icon: img3,
  },
  {
    id: "uniswap",
    icon: img5,
  },
  {
    id: "chainlink",
    icon: img6,
  },
  {
    id: "wrapped-bitcoin",
    icon: img7,
  },
  {
    id: "apecoin",
    icon: img8,
  },
  {
    id: "binancecoin",
    icon: img11,
  },
  {
    id: "binance-usd",
    icon: img12,
  },
  {
    id: "pancakeswap-token",
    icon: img13,
  },
  {
    id: "baby-doge-coin",
    icon: img15,
  },
  {
    id: "trust-wallet-token",
    icon: img16,
  },
  {
    id: "stepn",
    icon: img17,
  },
  {
    id: "coin98",
    icon: img18,
  },
];

export const allCoinsIds = ["ethereum", "tether", "usd-coin", "uniswap", "chainlink", "wrapped-bitcoin", "apecoin"];
