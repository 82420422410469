import cn from "classnames"

import "./SimpleButton.scss"
import { Link } from "react-router-dom"

type Props = {
  variant?: "default" | "colored" | "outlined" | "outlined-white" | "border" | "yellow" | "danger" | "filled" | "colored-round"
  onClick?: () => void
  href?: string
  disabled?: boolean
  text: string
  className?: string
  type?: "button" | "submit"
  isLeft?: boolean
  withEffect?: boolean
}

export const SimpleButton = ({
  variant = "default",
  text,
  onClick,
  href,
  type = "button",
  disabled,
  className,
  isLeft,
  withEffect,
}: Props): JSX.Element => {
  if (!!href?.length) {
    return (
      <Link
        to={href}
        className={cn("simple-button", variant, className, {
          left: isLeft,
          effect: withEffect,
        })}
      >
        {text}
        {withEffect && <span className="auth-btn-flare" />}
      </Link>
    )
  }

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cn("simple-button", variant, className, {
        left: isLeft,
        effect: withEffect,
      })}
    >
      {text}
      {withEffect && <span className="auth-btn-flare" />}
    </button>
  )
}
