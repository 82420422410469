import React, {useState} from "react"

import img1 from "assets/images/news-1.png"
import img2 from "assets/images/news-2.png"
import img3 from "assets/images/news-3.png"
import "./NewsHome.scss"
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../utils";

export const NewsHome = (): JSX.Element => {
  const blocks = [
    {
      title: "Lounge Finance Announces $6 Million Seed Funding to Revolutionize DeFi Staking",
      desc: "Melbourne, Australia, April 01, 2024 (GLOBE NEWSWIRE) -- In an unprecedented move that sets the stage for a new era in decentralized finance (DeFi), Lounge Finance is thrilled to announce the closure of a $6 million seed funding round. This substantial investment marks a significant milestone in the DeFi sector, signaling strong confidence from the venture capital community in Lounge Finance's mission to innovate and redefine the cryptocurrency staking landscape.",
      link:"https://hk.finance.yahoo.com/news/Lounge Finance-announces-6-million-seed-204500565.html",
      source: "Yahoo",
      icon: img1,
    },
    {
      title: "Lounge Finance: Innovative and Secure Staking Platform",
      desc: "Welcome to Lounge Finance, the avant-garde platform that opens up a realm of limitless earning opportunities through decentralized staking. As your premier gateway to cross-chain staking, we're here to revolutionize your crypto experience by amplifying your ability to earn across various blockchains. Our platform is designed with empowering features to cater to both newcomers and seasoned crypto enthusiasts alike.",
      icon: img2,
      link: "https://www.binance.com/en/square/post/5849753640458",
      source: 'Binance',
    },
    {
      title: "Lounge Finance Shatters Expectations with $30 Million De-Staking Inflow in First Week",
      desc: "In an unprecedented display of market confidence, Lounge Finance.finance, the newly launched multi-chain staking platform Lounge Finance, has already seen a staggering $30 million inflow for de-staking, signaling a robust start that surpasses industry expectations. Developed in 2023 and going live just seven days ago, Lounge Finance's early success is a testament to its innovative approach and the growing demand for more flexible and secure DeFi solutions.",
      link: "https://coinmarketcap.com/community/articles/660dac0f873fb64e162994ba/",
      source: 'CoinMarketCap',
      icon: img3,
    },
  ]
  return (
    <div className="news-home">
      <div className='news-home-header'>
        <h3 data-aos="fade-up-right" className="news-home-title">Our news</h3>
        <SimpleButton variant="colored-round" text="MORE NEWS"  data-aos="fade-up-left" href={routes.news}/>
      </div>
      {/*<div data-aos="fade-up" className="news-home-content">*/}
      {/*  {blocks.map((block, index) => (*/}
      {/*    <a href={block.link} target='_blank' rel='noreferrer noopener' key={index} className="news-home-content-item">*/}
      {/*      <div className="news-home-content-item-icon">*/}
      {/*        <img alt="news-home" src={block.icon}/>*/}
      {/*      </div>*/}
      {/*      <div className="news-home-content-item-title">{block.title}</div>*/}
      {/*      <div className="news-home-content-item-desc">{block.desc}</div>*/}
      {/*      /!*<div className="news-home-content-item-footer">From <b>{block.source}</b></div>*!/*/}
      {/*    </a>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  )
}
