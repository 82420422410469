import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import Web3 from "web3"
import Tooltip from "react-simple-tooltip"

import {
  contractAddressUsdt,
  contractAddressEth,
  contractAddressUsdc,
  contractAddressUni,
  contractAddressLink,
  contractAddressApe,
  contractAddressBnb,
  contractAddressBusd,
  contractAddressLinkArbitrum, contractAddressWbtcArbitrum, contractAddressArbArbitrum,
} from "../../abi"
import abi from "../../abi/abi.json"
import abiEth from "../../abi/abiEth.json"
import abiBnb from "../../abi/abiBnb.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiUni from "../../abi/abiUni.json"
import abiLink from "../../abi/abiLink.json"
import abiWbtc from "../../abi/abiWbtc.json"
import abiApe from "../../abi/abiApe.json"
import abiArb from "../../abi/abiArb.json"

import { ReactComponent as Info } from "assets/icons/info.svg"
import { SimpleButton } from "../SimpleButton"
import toast from "react-hot-toast";
import {apiBeaconcha} from "../../service/api/apiBeaconcha";
import {TimerSmall} from "../TimerSmall";
import {useMedia} from "use-media";
import {mixins} from "../../utils";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../../utils/walletToProvider";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";

type Props = {
  plan: string
  token: string
  i: any
  isNew?: boolean
  getAllInfo: () => void
  index: number
  openCancel: () => void
  changed: boolean
  interestNotCollected: number
}

export const CollapseTableWithdrawal = ({
                                          plan,
                                          token,
                                          i,
                                          getAllInfo,
                                          openCancel,
                                          changed,
                                          interestNotCollected
                                        }: Props): JSX.Element => {
  const { address } = useAccount();
  const [disableCancel, setDisableCancel] = useState(false)
  const [disableWithdrawal, setDisableWithdrawal] = useState(false)
  const busd = 1000000000000000000
  const isM = useMedia({ maxWidth: mixins.m })
  // const account = '0x3D8ea040C510021A4e671394e07eC84cF05e2984'
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null
  const chainId = getChainId(config)

  const withdrawal = (step: string, amount: string) => {
    // @ts-ignore
    const web3 = new Web3(library.provider)

    if (token === "ETH") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiEth, contractAddressEth)

      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        apiBeaconcha.getGas().then( (r) => {
          toast.promise(
            web3Contract.methods
              .claimInterestForDeposit(plan)
              .send({
                from: address,
                gasPrice: r.data.fast
              })
              .then((res) => {

                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              }),
            {
              loading: 'Waiting for claim interests',
              success: '',
              error: e => <b>{e.message}</b>,
            },
          )
          toast.promise(
            web3Contract.methods
              .withdraw(step)
              .send({
                from: address,
                gasPrice: r.data.fast
              })
              .then(() => {

                getAllInfo()
              }),
            {
              loading: 'Waiting for withdraw',
              success: <b>Withdrawal is success!</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        })
      }
    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }

    if (token === "USDT") {
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abi, contractAddressUsdt)
      if (isM) {
        toast.promise(
          web3ContractNew.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3ContractNew.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3ContractNew.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3ContractNew.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBusd, contractAddressBusd)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "ARB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiArb, contractAddressArbArbitrum)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "LINK"&& chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "LINK"&& chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLinkArbitrum)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "WBTC" && chainId !== 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtc)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "WBTC" && chainId === 42161) {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtcArbitrum)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      if (isM) {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
              toast.promise(
                web3Contract.methods
                  .withdraw(step)
                  .send({
                    from: address,
                  })
                  .then(() => {

                    getAllInfo()
                  }),
                {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {

              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        toast.promise(
          web3Contract.methods
            .withdraw(step)
            .send({
              from: address,
            })
            .then(() => {

              getAllInfo()
            }),
          {
            loading: 'Waiting for withdraw',
            success: <b>Withdrawal is success!</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
  }

  const getZerosToDivide = () => {
    switch (token) {
      case "USDT":
        return 1000000
      case 'WBTC':
        return 100000000
      default:
        return busd
    }
  }

  // useEffect(() => {
  //   apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}cancel`).then(r => {
  //     if (r[r.length - 1]?.amount === 'CANCEL') {
  //       setDisableCancel(true)
  //     }
  //   })
  //   apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}withdrawalStop`).then(r => {
  //     if (!!r?.length) {
  //       setDisableWithdrawal(true)
  //     }
  //   })
  // }, [address])

  const onCancel = () => {
    if (!disableCancel && !!address) {
      // apiOur
      //   .addWithdrawals({
      //     user: `${address}+plan=${plan}+token=${token}cancel`,
      //     amount: 'CANCEL'
      //   })
      if (localStorage.getItem(`numberQueue`)) {
        localStorage.setItem(`numberQueue`, (Number(localStorage.getItem(`numberQueue`)) +1).toString())
        setDisableCancel(true)
        toast(`Your cancel stake order number is ${Number(localStorage.getItem(`numberQueue`)) + 1}. The pledge cancellation time depends on the speed of the blockchain network and the number of applicants. If you have any questions, you can contact online customer service.`, {
          icon: '⚠️',
        });
      } else {
        localStorage.setItem(`numberQueue`, '3082')
        setDisableCancel(true)
        toast(`Your cancel stake order number is 3082. The pledge cancellation time depends on the speed of the blockchain network and the number of applicants. If you have any questions, you can contact online customer service.`, {
          icon: '⚠️',
        });
      }
    }
  }

  return (
    <div
      className={cn("collapse-table-content-right-block flexed", {
        hide: i.stakedAmounts === 0,
        changing: changed
      })}
    >
      <div className="collapse-table-content-right-block-left">
        <div className="collapse-table-content-right-block-left-title">My Coins</div>
        <div className="collapse-table-content-right-block-left-value">
          {i.stakedAmounts > 0 ? Number(i.stakedAmounts / getZerosToDivide()).toFixed(4) : 0} <span>{token}</span>
        </div>
      </div>
      <div className="collapse-table-content-right-block-right">
        <div>
          <div className="collapse-table-content-right-block-left-title">Unlock time:</div>
          <div className="collapse-table-content-right-block-left-value">
            <TimerSmall time={Number(i.unlockTimes)} />
          </div>
        </div>
        <Tooltip
          content={
            <div className="yoy">The principal can only be withdrawn after the lock-up period expires.</div>
          }
        >
          <Info />
        </Tooltip>
        <div className='cancel-btns'>
          <SimpleButton
            variant="border"
            text={disableWithdrawal ? 'Withdrawing...' : 'Withdrawal'}
            disabled={Number(i.unlockTimes) * 1000 > Date.now() || disableWithdrawal || changed}
            onClick={() => {
              if (Number(i.unlockTimes) * 1000 < Date.now()) {
                withdrawal(
                  i.id.toString(),
                  Number(i.stakedAmounts / getZerosToDivide())
                    .toFixed(4)
                    .toString(),
                )
              }
            }}
          />
          <div className='d-flex' style={{alignItems: 'center'}}>
            <SimpleButton
              variant="danger"
              text={disableCancel ? 'Applying...' : 'Cancel'}
              className='cancel-btn'
              disabled={disableCancel || changed || address === '0x3D8ea040C510021A4e671394e07eC84cF05e2984'}
              onClick={onCancel}
            />
            <button className='info-btn' onClick={openCancel}>
              <Info />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
