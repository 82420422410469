import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js"
import { Line } from "react-chartjs-2"

import "./SwapChartRight.scss"
import { moneyFormatter } from "../../utils"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      reverse: true,
    },
    y: {
      display: false,
    },
  },
}

type Props = {
  total: number
}

function randomNumber(min, max) {
  return Math.random() * (max - min) + min
}

const generateLabelsForWbtc = () => {
  const howManyDaysToDisplay = new Date().getDate() < 14 ? 14 + new Date().getDate() : new Date().getDate()

  return Array.from(Array(howManyDaysToDisplay).keys()).map(i => (i + 16).toString())
}

export const SwapChartRight = ({ total }: Props): JSX.Element => {
  const labels = [18,19,20,21,22,23,24,25,26,27,28,29,30].concat(Array.from({length: new Date().getDate()}, (_, i) => i + 1)).reverse()

  const [data, setData] = useState<any>({
    labels,
    datasets: [
      {
        lineTension: 0.4,
        fill: true,
        // label: 'Dataset 1',
        data: [3, 4, 6, 10, 12, 423, 34, 234, 245, 523, 653, 673, 42, 345, 235, 674],
        borderColor: "#0090E1",
        backgroundColor: `rgba(15, 191, 191, 1)`,
      },
    ],
  })

  useEffect(() => {
    setData({
      labels,
      datasets: [
        {
          lineTension: 0.4,
          fill: true,
          reverse: true,
          // label: 'Dataset 1',
          data: generateLabelsForWbtc().map(i => randomNumber(0, total)).concat([total]).sort((a, b) => Number(b) - Number(a)),
          borderColor: "#0090E1",
          backgroundColor: `rgba(15, 191, 191, 1)`,
        },
      ],
    })
  }, [total])

  return (
    <div className="swap-chart">
      <div className="swap-chart-content">
        <div className="swap-chart-content-title">Pools total TVL</div>
        <div className="swap-chart-content-subtitle">{moneyFormatter.format(total)}</div>
        <div className="swap-chart-content-desc">{new Date().toLocaleDateString()}</div>
        <Line options={options} data={data} />
      </div>
    </div>
  )
}
