import React from "react"

import "./Loading.scss"
import imgLogo from "../../assets/images/logo-dark.webp";
import imgLoading from "../../assets/icons/loading.svg";

export const Loading = (): JSX.Element => (
  <div className="loading" >
    <div className="loading-backdrop" />
    <div className='loading-content'>
      <img src={imgLoading} alt='logo' className='loading-content-loading'/>
      <img src={imgLogo} alt='logo' className='loading-content-logo'/>
    </div>
  </div>
)
