export const preloadData = [
  {
    tvl: 1999847.4360365185,
    icon: "/static/media/usdc.c49c8343.svg",
    name: "USD Coin",
    id: "usd-coin",
    token: "USDC",
    network: "Ethereum",
    address: "0xe081d9d8fd994f0a17cd1edc4e6941a0333f686d",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "14",
    index: 1,
    percent: "1.2",
    total: 2000031.4389289,
    quantity: "3500-25000",
  },
  {
    tvl: 1901175.7393240647,
    icon: "/static/media/eth.b22bb868.svg",
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x80fbb6122b8e023988e640db1ae348a10a7933e8",
    day: "30",
    index: 2,
    quantity: "15-50",
    percent: "2",
    total: 573.94382394,
  },
  {
    tvl: 1844781.7221384845,
    icon: "/static/media/tether.f506129c.svg",
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc2e246f77be48c18c013ce5ab99346eec15dfd74",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "90",
    index: 4,
    percent: "6.5",
    quantity: "70000-1000000",
    total: 1841099.5230923,
  },
  {
    tvl: 1727231.6559560865,
    icon: "/static/media/eth.b22bb868.svg",
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0xab6b4b11378a57933333e4acfdc45567dd78f14e",
    day: "14",
    index: 1,
    quantity: "0.5-20",
    percent: "0.8",
    total: 521.42288932,
  },
  {
    tvl: 1722814.8088866363,
    icon: "/static/media/usdc.c49c8343.svg",
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0xb8f1628f0945b215bd5202972246dcd185b2e958",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "90",
    index: 4,
    percent: "8",
    quantity: "70000-1000000",
    total: 1722973.3224323,
  },
  {
    tvl: 1625653.8156406286,
    icon: "/static/media/eth.b22bb868.svg",
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x49CaEB8175DC9Db709d4e6299CF8252ddDEE2738",
    day: "90",
    index: 4,
    quantity: "70-1000",
    percent: "8.5",
    total: 490.7523255,
  },
  {
    tvl: 1387983.492,
    icon: "/static/media/ape.0c1be788.svg",
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xbb0780090b4f69e5bbab9065104bffab357dfc55",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "14",
    index: 1,
    percent: "2",
    quantity: "2000-5000",
    total: 734382.8,
  },
  {
    tvl: 1317007.694076,
    icon: "/static/media/link.cb4efd52.svg",
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x8ab96777100aa0735509d8921e3e852bbb3f53a0",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "90",
    index: 4,
    percent: "14",
    quantity: "7000-100000",
    total: 74323.2333,
  },
  {
    tvl: 1213399.469408,
    icon: "/static/media/uni.10c8f0f6.svg",
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0x558ccfbb664c5e6cc624497a4aeccdcf2a565b68",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "60",
    index: 3,
    percent: "11",
    quantity: "500-10000",
    total: 108339.23834,
  },
  {
    tvl: 1084100.930535716,
    icon: "/static/media/usdc.c49c8343.svg",
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0xbd55337583699e5f4849e3412ca2e05c39940a01",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "60",
    index: 3,
    percent: "6",
    quantity: "30000-100000",
    total: 1084200.676998,
  },
  {
    tvl: 1002864.052896373,
    icon: "/static/media/tether.f506129c.svg",
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0xc0947c152cd08f423b34a890126074d2648941ab",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "60",
    index: 3,
    percent: "4",
    quantity: "30000-100000",
    total: 1000862.3282398932,
  },
  {
    tvl: 998121.3011839999,
    icon: "/static/media/link.cb4efd52.svg",
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x4889de78304826faf170642322723960637a28a6",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "60",
    index: 3,
    percent: "9",
    quantity: "3000-10000",
    total: 56327.3872,
  },
  {
    tvl: 934083.6847999999,
    icon: "/static/media/uni.10c8f0f6.svg",
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0xd11aae487b1eaccdbb9fb021c0e84b4f030c0d9c",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "30",
    index: 2,
    percent: "5",
    quantity: "300-2500",
    total: 83400.329,
  },
  {
    tvl: 892952.0807349307,
    icon: "/static/media/usdc.c49c8343.svg",
    name: "USD Coin",
    network: "Ethereum",
    id: "usd-coin",
    token: "USDC",
    address: "0x124cc44b7119fb592a774f466823f31885b60440",
    addAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    day: "30",
    index: 2,
    percent: "2.5",
    quantity: "15000-50000",
    total: 893034.239885,
  },
  {
    tvl: 890220.1015008,
    icon: "/static/media/uni.10c8f0f6.svg",
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0xb1102d897efeba4778fa7957619a5977efd0fb3d",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "90",
    index: 4,
    percent: "17",
    quantity: "1200-50000",
    total: 79483.937634,
  },
  {
    tvl: 815705.9015670543,
    icon: "/static/media/link.cb4efd52.svg",
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0x8d9dcab8c99930d3476a99b19943901f3c32e5fb",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "14",
    index: 1,
    percent: "1.5",
    quantity: "500-2000",
    total: 46033.06442252,
  },
  {
    tvl: 795663.3814165486,
    icon: "/static/media/eth.b22bb868.svg",
    name: "Ethereum",
    token: "ETH",
    id: "ethereum",
    network: "Ethereum",
    address: "0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789",
    day: "60",
    index: 3,
    quantity: "30-100",
    percent: "5",
    total: 240.1437823,
  },
  {
    tvl: 771695.1447279999,
    icon: "/static/media/link.cb4efd52.svg",
    name: "Chainlink",
    network: "Ethereum",
    token: "LINK",
    id: "chainlink",
    address: "0xf44c1b125e71c4b351ee2bef0e35d25ec76b08fc",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "30",
    index: 2,
    percent: "3.5",
    quantity: "1500-5000",
    total: 43549.3874,
  },
  {
    tvl: 753364.0799999998,
    icon: "/static/media/uni.10c8f0f6.svg",
    name: "Uniswap",
    network: "Ethereum",
    token: "UNI",
    id: "uniswap",
    address: "0x277c31565077d0fbd999e905466ee7289553c821",
    addAddress: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    day: "14",
    index: 1,
    percent: "2",
    quantity: "100-500",
    total: 67264.65,
  },
  {
    tvl: 734820.628248,
    icon: "/static/media/ape.0c1be788.svg",
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xfaf5137f19f0f30aece41e08f77ea274490f0386",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "365",
    index: 5,
    percent: "90",
    quantity: "15000-50000",
    total: 388793.9832,
  },
  {
    tvl: 702826.0853710865,
    icon: "/static/media/tether.f506129c.svg",
    name: "Tether",
    token: "USDT",
    network: "Ethereum",
    id: "tether",
    address: "0x290d7c4a8b0343605330fb1f7bab174655c4c1b7",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "30",
    index: 2,
    percent: "1.6",
    quantity: "15000-50000",
    total: 701423.2388933,
  },
  {
    tvl: 676676.46037,
    icon: "/static/media/wbtc.c9315cf9.svg",
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1cb17a66dc606a52785f69f08f4256526abd4943",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "60",
    index: 3,
    percent: "2.5",
    quantity: "0.5-5",
    total: 10.473895,
  },
  {
    tvl: 674247.1305540265,
    icon: "/static/media/tether.f506129c.svg",
    name: "Tether",
    token: "USDT",
    id: "tether",
    network: "Ethereum",
    address: "0x051f1d88f0af5763fb888ec4378b4d8b29ea3319",
    addAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    day: "14",
    index: 1,
    percent: "0.7",
    quantity: "5000-10000",
    total: 672901.32789823,
  },
  {
    tvl: 673625.9201044,
    icon: "/static/media/wbtc.c9315cf9.svg",
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0xefa6dbc560308338867ab6fa69a8c8f6bf14167e",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "30",
    index: 2,
    percent: "1",
    quantity: "0.3-1",
    total: 10.4266774,
  },
  {
    tvl: 599315.62082,
    icon: "/static/media/wbtc.c9315cf9.svg",
    name: "Wrapped Bitcoin",
    network: "Ethereum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x0ef6aeb825dc4c9983d551f8afefaae9d79165c6",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "14",
    index: 1,
    percent: "0.4",
    quantity: "0.1-0.5",
    total: 9.27647,
  },
  {
    tvl: 596892.1334692,
    icon: "/static/media/wbtc.c9315cf9.svg",
    network: "Ethereum",
    name: "Wrapped Bitcoin",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1e227979f0b5bc691a70deaed2e0f39a6f538fd5",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "90",
    index: 4,
    percent: "4",
    quantity: "0.8-100",
    total: 9.2389582,
  },
  {
    tvl: 204189.88528259998,
    icon: "/static/media/ape.0c1be788.svg",
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0x2fee4d3d38e59774a4155a7d5f089308dbeb63ae",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "60",
    index: 3,
    percent: "12",
    quantity: "5000-12000",
    total: 108036.97634,
  },
  {
    tvl: 202965.66322199997,
    icon: "/static/media/ape.0c1be788.svg",
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0xc79707530a2aeb59a587780241ca74d128642fdd",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "30",
    index: 2,
    percent: "5.5",
    quantity: "3000-8000",
    total: 107389.2398,
  },
  {
    tvl: 132807.16638,
    icon: "/static/media/ape.0c1be788.svg",
    name: "ApeCoin",
    network: "Ethereum",
    token: "APE",
    id: "apecoin",
    address: "0x7303faf749ba82f71c998ca6e27fe3f40c006718",
    addAddress: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    day: "90",
    index: 4,
    percent: "18",
    quantity: "8000-30000",
    total: 70268.342,
  },
];
