export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/LoungeProtocol",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/LoungeFinance",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  audit: "https://lounge.audits-scrutify.io/",
};
