import img1 from "assets/icons/pool/eth.svg";
import img2 from "assets/icons/pool/tether.svg";
import img3 from "assets/icons/pool/usdc.svg";
import img5 from "assets/icons/pool/uni.svg";
import img6 from "assets/icons/pool/link.svg";
import img7 from "assets/icons/pool/wbtc.svg";
import img8 from "assets/icons/pool/ape.svg";

export const poolData = [
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    network: "Ethereum",
    id: "ethereum",
    addresses: [
      "0xab6b4b11378a57933333e4acfdc45567dd78f14e",
      "0xa29e963992597b21bcdcaa969d571984869c4ff5",
      "0x6bfad42cfc4efc96f529d786d643ff4a8b89fa52",
      "0xefb2e870b14d7e555a31b392541acf002dae6ae9",
    ],
    total: 4411.26282106,
    quantity: ["0.1-20", "15-50", "30-100", "70-1000"],
    percents: ["8.9", "16.8", "34", "48"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    id: "tether",
    network: "Ethereum",
    addresses: [
      "0x051f1d88f0af5763fb888ec4378b4d8b29ea3319",
      "0x290d7c4a8b0343605330fb1f7bab174655c4c1b7",
      "0xc0947c152cd08f423b34a890126074d2648941ab",
      "0xc2e246f77be48c18c013ce5ab99346eec15dfd74",
    ],
    addAddress: [
      "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "0xdac17f958d2ee523a2206206994597c13d831ec7",
    ],
    total: 13846286.41812303,
    quantity: ["500-10000", "5000-50000", "20000-100000", "50000-1000000"],
    percents: ["6.1", "10.8", "27", "31"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img3,
    name: "USD Coin",
    id: "usd-coin",
    network: "Ethereum",
    token: "USDC",
    addresses: [
      "0xe081d9d8fd994f0a17cd1edc4e6941a0333f686d",
      "0xbd55337583699e5f4849e3412ca2e05c39940a01",
      "0x968d03f4492fa7c101a9383b84489a1b7cc7bd3e",
      "0xb8f1628f0945b215bd5202972246dcd185b2e958",
    ],
    addAddress: [
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    ],
    total: 12900239.6782442,
    quantity: ["3500-25000", "15000-50000", "30000-100000", "70000-1000000"],
    percents: ["1.2", "2.5", "6", "8"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    network: "Ethereum",
    id: "wrapped-bitcoin",
    addresses: [
      "0x0ef6aeb825dc4c9983d551f8afefaae9d79165c6",
      "0xefa6dbc560308338867ab6fa69a8c8f6bf14167e",
      "0x1cb17a66dc606a52785f69f08f4256526abd4943",
      "0x1e227979f0b5bc691a70deaed2e0f39a6f538fd5",
    ],
    addAddress: [
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    ],
    total: 204.4160006,
    quantity: ["0.1-0.5", "0.3-1", "0.5-5", "0.8-100"],
    percents: ["0.4", "1", "2.5", "4"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    network: "Ethereum",
    id: "chainlink",
    addresses: [
      "0x8d9dcab8c99930d3476a99b19943901f3c32e5fb",
      "0xf44c1b125e71c4b351ee2bef0e35d25ec76b08fc",
      "0x4889de78304826faf170642322723960637a28a6",
      "0x8ab96777100aa0735509d8921e3e852bbb3f53a0",
    ],
    addAddress: [
      "0x514910771af9ca656af840dff83e8264ecf986ca",
      "0x514910771af9ca656af840dff83e8264ecf986ca",
      "0x514910771af9ca656af840dff83e8264ecf986ca",
      "0x514910771af9ca656af840dff83e8264ecf986ca",
    ],
    total: 508913.072323,
    quantity: ["500-2000", "1500-5000", "3000-10000", "7000-100000"],
    percents: ["1.5", "3.5", "9", "14"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    network: "Ethereum",
    id: "uniswap",
    addresses: [
      "0x277c31565077d0fbd999e905466ee7289553c821",
      "0xd11aae487b1eaccdbb9fb021c0e84b4f030c0d9c",
      "0x558ccfbb664c5e6cc624497a4aeccdcf2a565b68",
      "0xb1102d897efeba4778fa7957619a5977efd0fb3d",
    ],
    addAddress: [
      "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    ],
    total: 112428.154974,
    quantity: ["100-500", "300-2500", "500-10000", "1200-50000"],
    percents: ["2", "5", "11", "17"],
    days: ["14", "30", "60", "90"],
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    network: "Ethereum",
    id: "apecoin",
    addresses: [
      "0xbb0780090b4f69e5bbab9065104bffab357dfc55",
      "0xc79707530a2aeb59a587780241ca74d128642fdd",
      "0x2fee4d3d38e59774a4155a7d5f089308dbeb63ae",
      "0x7303faf749ba82f71c998ca6e27fe3f40c006718",
      "0xfaf5137f19f0f30aece41e08f77ea274490f0386",
    ],
    addAddress: [
      "0x4d224452801aced8b2f0aebe155379bb5d594381",
      "0x4d224452801aced8b2f0aebe155379bb5d594381",
      "0x4d224452801aced8b2f0aebe155379bb5d594381",
      "0x4d224452801aced8b2f0aebe155379bb5d594381",
      "0x4d224452801aced8b2f0aebe155379bb5d594381",
    ],
    total: 768171.34134,
    quantity: ["2000-5000", "3000-8000", "5000-12000", "8000-30000", "15000-50000"],
    percents: ["2", "5.5", "12", "18", "90"],
    days: ["14", "30", "60", "90", "365"],
  },
];
