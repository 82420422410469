import React, { useEffect, useState } from "react"

import "./Table.scss"
import { Input } from "../Input"
import { CollapseTable } from "../CollapseTable"
import { NetworkDropdown } from "../NetworkDropdown"
import { apiCoin } from "../../service/api/apiCoinGecko"
import useDebounce from "../../utils/useDebounce"
import { useSearchParams } from "react-router-dom"
import { scrollToElement } from "../../utils"
import { Loading } from "../Loading"
import {poolDataSeparated} from "./dataSeparated";
import { useAccount } from "wagmi";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";
import {poolDataSeparatedBsc} from "./dataSeparatedBsc";
import {poolDataSeparatedArbitrum} from "./dataSeparatedArb";

type Props = {
  stakedDisplay: boolean
  earn?: boolean
  openCancel: () => void
  openClaimAnn: () => void
  openTransfer: (current: string, money: number, interests: number, length: number, allRecords: string[]) => void
}

export const Table = ({ stakedDisplay,openClaimAnn, openCancel, openTransfer, earn }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const [input, setInput] = useState("")
  const [index, setIndex] = useState<undefined | number>(undefined)
  const [token, setToken] = useState<undefined | string>(undefined)
  const [filteredPoolData, setFilteredPoolData] = useState<any>([])
  const debouncedSearch = useDebounce(input, 500)
  const [stat, setStat] = useState<any[]>([])
  const [loaded, setLoaded] = useState(false)
  const { address } = useAccount();
  const chainId = getChainId(config)

  useEffect(() => {
    if (chainId === 56) {
      if (debouncedSearch) {
        setFilteredPoolData(
          poolDataSeparatedBsc.filter(
            i =>
              i.token.toLocaleLowerCase().match(debouncedSearch.toLowerCase()) ||
              i.name.toLocaleLowerCase().match(debouncedSearch.toLowerCase()),
          ),
        )
      } else {
        setFilteredPoolData(poolDataSeparatedBsc)
      }
      return
    }
    if (chainId === 42161) {
      if (debouncedSearch) {
        setFilteredPoolData(
          poolDataSeparatedArbitrum.filter(
            i =>
              i.token.toLocaleLowerCase().match(debouncedSearch.toLowerCase()) ||
              i.name.toLocaleLowerCase().match(debouncedSearch.toLowerCase()),
          ),
        )
      } else {
        setFilteredPoolData(poolDataSeparatedArbitrum)
      }
      return
    }

    if (debouncedSearch) {
      setFilteredPoolData(
        poolDataSeparated.filter(
          i =>
            i.token.toLocaleLowerCase().match(debouncedSearch.toLowerCase()) ||
            i.name.toLocaleLowerCase().match(debouncedSearch.toLowerCase()),
        ),
      )
    } else {
      setFilteredPoolData(poolDataSeparated)
    }
  }, [debouncedSearch,chainId])

  useEffect(() => {
    apiCoin
      .getStat()
      .then(rOther => {
        setStat(rOther)
      })
      .finally(() => setLoaded(true))
  }, [])

  useEffect(() => {
    if (chainId === 1 || !address) {
      setFilteredPoolData(poolDataSeparated)
    }
    if (chainId === 42161) {
      setFilteredPoolData(poolDataSeparatedArbitrum)
    }
    if (chainId === 56) {
      setFilteredPoolData(poolDataSeparatedBsc)
    }
  }, [chainId, address])

  useEffect(() => {
    if (!!search.get("token")?.length) {
      setTimeout(() => {
        scrollToElement(`${search.get("token")}`)
      }, 500)
      setToken(`${search.get("token")}`)
    }
    if (!!search.get("index")?.length) {
      setIndex(Number(`${search.get("index")}`))
    }
  }, [search])

  const getFirstPlan = (token: string, days: string, index: number) => {
    if (token === 'ETH' || token === 'UNI' || token === "WBTC" || token === "USDT") {
      return days
    }

    return index.toString()
  }

  return (
    <div className="table">
      {!loaded && <Loading />}
      <div className="table-header">
        <div className="table-header-left">
          <div className="table-header-left-title">Flexible Lock-Up Choices</div>
          <div className="table-header-left-desc">
            Explore various staking plans tailored to your preferences, offering different lock-up periods for optimal
            returns on your selected currency.
          </div>
        </div>
        <div className="table-header-right">
          <NetworkDropdown chainId={chainId} mobileRight account={address ? address : ""} />
          <Input onChange={v => setInput(v)} value={input} placeholder="Search All" variant="search" />
        </div>
      </div>
      <div className="table-content">
        {filteredPoolData?.map((i: any, indexOther) => (
          <CollapseTable
            key={indexOther}
            index={i.index}
            stakedDisplay={stakedDisplay}
            token={i.token}
            img={i.icon}
            checkAddress={''}
            plan={getFirstPlan(i.token, i.day, i.index)}
            quantity={`${i.quantity} ${i.token}`}
            percent={i.percent}
            addAddress={''}
            day={i.day}
            stat={stat}
            id={i.id}
            opened={index === i.index && token === i.token}
            isNew
            openCancel={openCancel}
            openTransfer={openTransfer}
            earn={earn}
            openClaimAnn={openClaimAnn}
            total={i.total}
          />
        ))}
      </div>
    </div>
  )
}
