import React, {useState} from "react"

import { Medias, routes } from "utils"

import imgLogo from "assets/images/logo.webp"
import "./Footer.scss"
import { Link, useSearchParams } from "react-router-dom"
import {ConnectKitButton} from "connectkit";
import {Input} from "../Input";
import {SimpleButton} from "../SimpleButton";
import toast from "react-hot-toast";

export const Footer = (): JSX.Element => {
  const [search] = useSearchParams()
  const [email, setEmail] = useState('')

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!email.length) {
  //     return;
  //   }
  //   await toast.promise(
  //     apiOur.addRefAddress({user: email, follower: email}).then(() => setEmail('')),
  //     {
  //       loading: 'Sending request...',
  //       success: <b>Thanks for your subscribe! ✅</b>,
  //       error: e => <b>{e.message}</b>,
  //     },
  //   )
  // }

  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={imgLogo} alt='logo' className='footer-left-logo' />
        <ul>
          Documents
          <li>
            <Link className="footer-left-link" onClick={() => {window.scrollTo({
              top: 0,
            })}} to={`${routes.privacy}?${search.toString()}`}>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className="footer-left-link" onClick={() => {window.scrollTo({
              top: 0,
            })}} to={`${routes.terms}?${search.toString()}`}>
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link className="footer-left-link" onClick={() => {window.scrollTo({
              top: 0,
            })}} to={`${routes.cookies}?${search.toString()}`}>
              Cookie Policy
            </Link>
          </li>
        </ul>
        <div className='footer-left-combined'>
          <ul>
            Email:
            <li>
              <a className='footer-left-link' href='mailto:support@lounge.finance'>support@lounge.finance</a>
            </li>
          </ul>
          <ul>
            Address:
            <li>
              <address>
                70 City Road,Southbank Melbourne, VIC 3006 Australia
              </address>
            </li>
          </ul>
          <p className="footer-reserved">Copyright © 2024 Lounge Finance. All rights reserved</p>
        </div>
      </div>
      <div className='footer-right'>
        <h5>Stay updated for the latest news, drops and programs</h5>
        <form className='news-home-input'>
          <Input onChange={value => setEmail(value)} value={email} placeholder='Your email address'
                 type='email'/>
          <SimpleButton text='Subscribe' type='submit'/>
        </form>
        <Medias/>
      </div>
    </footer>
  )
}
