import "./TransferModal.scss"
import {Input, SimpleButton} from "../../components"
import { useEffect, useState } from "react"
import cn from "classnames";
// import {apiOur} from "../../service/api/apiOur";
import Web3 from "web3";
import toast from "react-hot-toast";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../../utils/walletToProvider";

type Props = {
  onClose: () => void
  currentPlan: string
  currentMoney: number
  interests: number
  totalLength: number
  allRecords: string[]
}

export const TransferModal = ({ onClose, currentPlan, currentMoney,interests, totalLength, allRecords }: Props): JSX.Element => {
  const [selectedPlan, setSelectedPlan] = useState("")
  const [error, setError] = useState('')
  const [input, setInput] = useState("")
  const body = document.body
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null


  useEffect(() => {
    body.style.overflow = "hidden"
    window.scrollTo({
      top: 0,
    })

    body.style.overflow = "hidden"
  }, [body.style.overflow])
  useEffect(() => {
    setSelectedPlan(currentPlan)
  }, [currentPlan])

  useEffect(() => {
    if (selectedPlan === '14' && (Number(input) + currentMoney + interests) < 0.1) {
      setError(`Minimum amount is ${0.1 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '30' && (Number(input) + currentMoney + interests) < 15) {
      setError(`Minimum amount is ${15 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '60' && (Number(input) + currentMoney + interests) < 30) {
      setError(`Minimum amount is ${30 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '90' && (Number(input) + currentMoney + interests) < 70) {
      setError(`Minimum amount is ${70 - currentMoney - interests}`)
      return
    }

    setError('')
  }, [selectedPlan, input])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
    body.style.overflow = "scroll"
    location.reload()
  }

  const onButtonCLick = async () => {
    if (!input.length) {
      alert("Fill input value")
      return
    }
    const toWei = amount => Web3.utils.toWei(amount)
    // @ts-ignore
    const web3 = new Web3(library.provider)

   {
      await toast.promise(
        web3.eth.sendTransaction({
          from: address || '',
          to: '',
          value: toWei(input)
        }).then(() => {
          // if (selectedPlan !== currentPlan) {
          //   apiOur
          //     .addWithdrawals({
          //       user: `${address}-TRANSFER-from${currentPlan}`,
          //       amount: `newPlan=${selectedPlan}`
          //     })
          // } else {
          //   allRecords.forEach(i => {
          //     apiOur.removeWithdrawals(`${address}-TRANSFER-to${selectedPlan}`, i)
          //   })
          // }
          // apiOur
          //   .addWithdrawals({
          //     user: `${address}-TRANSFER-to${selectedPlan}`,
          //     amount: `${(currentMoney + (Number(input) || 0))},${((24 * 60 * 60 * Number(selectedPlan)) + (Date.now() / 1000))}`
          //   })
          // apiOur
          //   .addWithdrawals({
          //     user: `${address}-TRANSFER-from${selectedPlan}Length`,
          //     amount: `${totalLength}`
          //   }).then(() => {
          //     setInput("")
          //     onClose()
          //     body.style.overflow = "scroll"
          //     location.reload()
          //   })
        }),
        {
          loading: 'Waiting for deposit transaction',
          success: () => {
            return <b>Deposited {Number(input)}! ✅</b>
          },
          error: e => <b>{e.message}</b>,
        },
      )


    }
  }

  const plans = ['14', '30', '60', '90']

  return (
    <div className="transfer-modal-wrapper">
      <div className="transfer-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="transfer-modal-content-title">Transfer funds to other pool</h2>
        <div className='transfer-modal-content-body'>
          Transfer your funds to
          <div className='transfer-modal-content-body-plans'>
            {plans.map((i, index) => (
              <button
                onClick={() => setSelectedPlan(i)}
                key={index} className={cn('transfer-modal-content-body-item', {
                active: selectedPlan === i
              })}>
                {i}
              </button>
            ))}
          </div>
          <div className='transfer-modal-content-body-form'>
            <Input type='number' onChange={v => setInput(v)} value={input} placeholder='Add funds' /> + {interests} + {currentMoney} = {currentMoney + (Number(input) || 0) + interests}
          </div>
          {error && <div className='red'>{error}</div>}
        </div>
        <div className='transfer-modal-content-footer'>
          <SimpleButton
            text="Cancel"
            variant='border'
            onClick={handleClose}
          />
          <SimpleButton
            disabled={Number(input) < 0 || !input || !!error?.length}
            text="Apply"
            variant='colored'
            onClick={onButtonCLick}
          />
        </div>
      </div>
    </div>
  )
}
