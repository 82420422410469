import React from "react"

import img1Src from "assets/icons/chains/1.svg"
import img2Src from "assets/icons/chains/2.svg"
import img3Src from "assets/icons/chains/3.svg"
import img4Src from "assets/images/base.png"
import img5Src from "assets/icons/chains/5.svg"
import img6Src from "assets/icons/chains/6.svg"
import img7Src from "assets/icons/chains/7.svg"
import img8Src from "assets/icons/chains/8.svg"
import img9Src from "assets/icons/chains/9.svg"
import img10Src from "assets/icons/chains/10.svg"
import imgLogoSrc from "assets/images/logo-dark.webp"
import "./Chains.scss"
import cn from "classnames";


export const Chains = (): JSX.Element => {
  const chainsList = [
    {
      image: img1Src,
      link: "https://manta.network/",
    },
    {
      image: img2Src,
      link: "https://aptosfoundation.org/",
    },
    {
      image: img3Src,
      link: "https://polygon.technology/",
    },
    {
      image: img4Src,
      link: "https://www.base.org/",
    },
    {
      image: img5Src,
      link: "https://www.avax.network/",
    },
    {
      link: 'logo',
    },
    {
      image: img6Src,
      link: "https://fantom.foundation/",
    },
    {
      image: img7Src,
      link: "https://arbitrum.io/",
    },
    {
      image: img8Src,
      link: "https://www.optimism.io/",
    },
    {
      image: img9Src,
      link: "https://ethereum.org/",
    },
    {
      image: img10Src,
      link: "https://www.bnbchain.org/",
    },
  ]
  return (
    <div className="chains">
      <h3 data-aos="fade-up">Pushing the frontier, together</h3>
      <div data-aos="fade-up" className='chains-wrapper'>
        {chainsList.map((i, index) => i.link === 'logo' ? (
            <div key={index}
                 className={cn('chains-wrapper-item', `chains-wrapper-item-${index}`)}>
              <img src={imgLogoSrc} alt='logo' className='chains-logo'/>
            </div>
        ): (
          <a key={index} href={i.link} target='_blank' rel='noreferrer noopener'
             className={cn('chains-wrapper-item', `chains-wrapper-item-${index}`)}>
            <img src={i.image} alt='chain'/>
          </a>
        ))}
      </div>
    </div>
  )
}
