import img6 from "assets/icons/pool/link.svg";
import img7 from "assets/icons/pool/wbtc.svg";
import img8 from "assets/icons/pool/arbitrum.webp";

export const poolDataSeparatedArbitrum = [
  {
    icon: img8,
    name: "Arbitrum",
    network: "Arbitrum",
    token: "ARB",
    id: "arbitrum",
    day: "14",
    index: 1,
    percent: "2",
    quantity: "2000-5000",
    total: 1091332.23432,
  },
  {
    icon: img8,
    name: "Arbitrum",
    network: "Arbitrum",
    token: "ARB",
    id: "arbitrum",
    day: "30",
    index: 2,
    percent: "5.5",
    quantity: "3000-8000",
    total: 823892.523,
  },
  {
    icon: img8,
    name: "Arbitrum",
    network: "Arbitrum",
    token: "ARB",
    id: "arbitrum",
    day: "60",
    index: 3,
    percent: "12",
    quantity: "5000-12000",
    total: 689334.687,
  },
  {
    icon: img8,
    name: "Arbitrum",
    network: "Arbitrum",
    token: "ARB",
    id: "arbitrum",
    day: "90",
    index: 4,
    percent: "18",
    quantity: "8000-30000",
    total: 930291.7725,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Arbitrum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x0ef6aeb825dc4c9983d551f8afefaae9d79165c6",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "14",
    index: 1,
    percent: "0.4",
    quantity: "0.1-0.5",
    total: 31.4323,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Arbitrum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0xefa6dbc560308338867ab6fa69a8c8f6bf14167e",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "30",
    index: 2,
    percent: "1",
    quantity: "0.3-1",
    total: 29.93492,
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    network: "Arbitrum",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1cb17a66dc606a52785f69f08f4256526abd4943",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "60",
    index: 3,
    percent: "2.5",
    quantity: "0.5-5",
    total: 46.2375,
  },
  {
    icon: img7,
    network: "Arbitrum",
    name: "Wrapped Bitcoin",
    token: "WBTC",
    id: "wrapped-bitcoin",
    address: "0x1e227979f0b5bc691a70deaed2e0f39a6f538fd5",
    addAddress: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    day: "90",
    index: 4,
    percent: "4",
    quantity: "0.8-100",
    total: 42.39930,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Arbitrum",
    token: "LINK",
    id: "chainlink",
    address: "0x8d9dcab8c99930d3476a99b19943901f3c32e5fb",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "14",
    index: 1,
    percent: "1.5",
    quantity: "500-2000",
    total: 25531.2356767,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Arbitrum",
    token: "LINK",
    id: "chainlink",
    address: "0xf44c1b125e71c4b351ee2bef0e35d25ec76b08fc",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "30",
    index: 2,
    percent: "3.5",
    quantity: "1500-5000",
    total: 19376.39923031,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Arbitrum",
    token: "LINK",
    id: "chainlink",
    address: "0x4889de78304826faf170642322723960637a28a6",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "60",
    index: 3,
    percent: "9",
    quantity: "3000-10000",
    total: 34866.783298,
  },
  {
    icon: img6,
    name: "Chainlink",
    network: "Arbitrum",
    token: "LINK",
    id: "chainlink",
    address: "0x8ab96777100aa0735509d8921e3e852bbb3f53a0",
    addAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
    day: "90",
    index: 4,
    percent: "14",
    quantity: "7000-100000",
    total: 42834.93999,
  },
];
