import React, { useEffect, useState } from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom"

import { hrefs, mixins, routes, useScrollPosition} from "utils"

import "./Header.scss"
import { ReactComponent as Polygon } from "assets/icons/polygon.svg"
import LogoMobile from "assets/images/logo.webp"
import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { ReactComponent as Menu } from "assets/icons/menu.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import { ReactComponent as IconAssets } from "assets/icons/header/assets.svg"
import { ReactComponent as IconPools } from "assets/icons/header/pools.svg"
import { ReactComponent as IconReferral } from "assets/icons/header/referral.svg"
import { ReactComponent as IconSwap } from "assets/icons/header/swap.svg"
import { ReactComponent as IconAnalytics } from "assets/icons/header/analytics.svg"
import { ReactComponent as IconFaq } from "assets/icons/header/faq.svg"
import { ReactComponent as IconDocs } from "assets/icons/header/docs.svg"
import { ReactComponent as IconNews } from "assets/icons/header/news.svg"

import { AccountDropdown } from "../AccountDropdown"
import { NetworkDropdown } from "../NetworkDropdown"
import { WalletModal} from "../../modals"
// import {apiOur} from "../../service/api/apiOur";
import {useAccount} from "wagmi";
import { ConnectKitButton } from 'connectkit';
import {getChainId} from "@wagmi/core";
import {config} from "../../index";
import {SimpleButton} from "../SimpleButton";

export const Header = (): JSX.Element => {
  const scroll = useScrollPosition()
  const [search, setSearch] = useSearchParams()
  const [sidebar, setSidebar] = useState(false)
  const [wallet, setWallet] = useState(false)
  const { address } = useAccount();
  const chainId = getChainId(config)
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (address) {
      // apiOur.getUserConnected().then((r) => {
      //   if (!r.map(i => i.address).includes(address)) {
      //     apiOur.addUserConnected({address})
      //   }
      // })
    }
  }, [address])


  const toggleSidebar = () => setSidebar(!sidebar)

  const links = [
    {
      title: "Pools",
      link: `${routes.farms}?${search.toString()}`,
      defaultLink: routes.farms,
      icon: <IconPools />
    },
    {
      title: "Staking Assets",
      link: `${routes.pool}?${search.toString()}`,
      defaultLink: routes.pool,
      icon: <IconAssets />
    },
    {
      title: "Referral",
      link: `${routes.referral}?${search.toString()}`,
      defaultLink: routes.referral,
      icon: <IconReferral />
    },
    {
      title: "Analytics",
      link: `${routes.swapInfo}?${search.toString()}`,
      defaultLink: routes.swapInfo,
      icon: <IconAnalytics />
    },
    {
      title: "Swap",
      link: `${routes.swap}?${search.toString()}`,
      defaultLink: routes.swap,
      icon: <IconSwap />
    },
  ]
  const smallLinks = [
    {
      title: "Audit",
      icon: <IconAnalytics />,
      href: hrefs.audit,
    },
    {
      title: "Docs",
      link: `${routes.docs}?${search.toString()}`,
      icon: <IconDocs />
    },
    {
      title: "News",
      link: `${routes.news}?${search.toString()}`,
      icon: <IconNews />
    },
    // {
    //   title: "Articles",
    //   link: `${routes.articles}?${search.toString()}`,
    //   icon: <IconDocs />
    // },
    {
      title: "FAQ",
      link: `${routes.faq}?${search.toString()}`,
      icon: <IconFaq />
    },
  ]



  const
    checkForLocation = () => {
      return location.pathname === '/' || location.pathname === routes.faq || location.pathname === routes.docs|| location.pathname === routes.privacy|| location.pathname === routes.terms|| location.pathname === routes.cookies || location.pathname === routes.news || location.pathname === routes.articles;
    }

  return (
    <header
      className={cn("sidebar", {
        show: sidebar,
        offset: scroll > 30,
        dark: checkForLocation()
      })}
    >
      <div className="sidebar-header">
        {(!isM && checkForLocation()) && (
          <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-desk">
            <Logo className="sidebar-header-logo" />
          </Link>
        )}
        {isM && (
          <div className='sidebar-header-left-mobile'>
            <button className="sidebar-header-btn" onClick={toggleSidebar}>
              {sidebar ? <Close/> : <Menu/>}
            </button>
            <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-mobile">
              <img src={LogoMobile} alt='logo' className="sidebar-header-logo"/>
            </Link>
          </div>
        )}
        {isM && (
          <div className="sidebar-header-right">
            {checkForLocation() ? (
              <SimpleButton text='Enter App' href={routes.farms}/>
            ) : (
              <>
                {!!address?.length && <NetworkDropdown chainId={chainId} account={address}/>}
                {!!address?.length ? (
                  <AccountDropdown account={address} openWallet={() => setWallet(true)}/>
                ) : (
                  <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isM && (
        <div
          className={cn("header-sidebar", {
            active: sidebar,
          })}
        >
          {(!checkForLocation() ? links : smallLinks).map((link, index) =>
            link.link ?
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                disabled={link.disabled}
                key={index}
                className={cn('sidebar-left-block-item', {
                  active: location.pathname.match(link.link),
                })}
              >
                {link.icon}
                {link.title}
              </button> : (
                <a
                  href={link.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={index}
                  className={cn('sidebar-left-block-item', {
                    disabled: link.disabled
                  })}
                >
                  {link.icon}
                  {link.title}
                </a>
              )
          )}
        </div>
      )}
      {(!isM && !checkForLocation()) && (
        <div className='sidebar-left-block'>
          <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-desk sidebar-left-block-logo">
            <Logo className="sidebar-header-logo" />
          </Link>
          {links.map((link, index) =>
            <button
              onClick={() => {
                navigate(link.link || "")
                setSidebar(false)
              }}
              key={index}
              className={cn('sidebar-left-block-item', {
                active: location.pathname.match(link.link),
              })}
            >
              {link.icon}
              {link.title}
            </button>
          )}
        </div>
      )}
      {(!isM && checkForLocation()) && (
        <div className='sidebar-right-content'>
          {smallLinks.map((link, index) =>
            link.link ? (
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                key={index}
                className={cn('sidebar-navigation-item', {})}
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <Polygon/>
                  Coming Soon...
                </div>
              </button>
            ) : (
              <a
                href={link.href}
                target="_blank"
                rel="noreferrer noopener"
                key={index}
                className={cn('sidebar-navigation-item', {
                })}
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <Polygon/>
                  Coming Soon...
                </div>
              </a>
            ),
          )}
        </div>
      )}
      {!isM && (
        <div className="sidebar-right">
          {checkForLocation() ? (
            <SimpleButton text='Enter App' href={routes.farms}/>
          ) : (
            <>
              {!!address?.length && <NetworkDropdown chainId={chainId} account={address}/>}
              {!!address?.length ? (
                <AccountDropdown account={address} openWallet={() => setWallet(true)}/>
              ) : (
                <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
              )}
            </>
          )}
        </div>
      )}
      {wallet && <WalletModal onClose={() => setWallet(false)}/>}
    </header>
  )
}
